
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Layout from 'containers/Layout';
import React from 'react';
import LandingPageContainer from 'containers/landing-page/LandingPageContainer';
import { GetServerSidePropsContext } from 'next';
import { staticMetaContent } from 'static/meta';
import { collection } from 'services/db/firestoreApi';
import { CustomerAddress, Region } from 'klikni-jadi-shared-stuff';
import { entries } from 'lodash';

export default function Home({ routerQuery, url, regions }) {
    return (
        <Layout
            isLandingPage
            routerQuery={routerQuery}
            transparentSearch={false}
            url={url}
            metaTags={staticMetaContent['homepage']}
        >
            <LandingPageContainer regions={regions} />
        </Layout>
    );
}

 async function _getServerSideProps(context: GetServerSidePropsContext) {
    const start = Date.now();

    // console.log('start', Date.now() - start);

    const { getServerSideCustomer } = await import('ssr');
    const { CURRENT_USER_GET } = await import('redux/constants/users');
    const { StateStatus } = await import('core/design/StateStatus');

    const query = context.query || {};

    const data = await getServerSideCustomer(context);

    const regions = await collection<Region>('configuration/local/regions');

    if (data.customer) {
        const { res } = context;
        const user = data.customer;

        const _addresses = entries(user.addresses);
        let userAddress: CustomerAddress = null;
        let id: string = null;

        const primary = _addresses.find(([, a]) => a.isDefault);
        if (primary) {
            userAddress = primary[1];
            id = primary[0];
        }

        if (userAddress) {
            res.writeHead(307, {
                Location: `/partners/discover?nearby=${[userAddress.location?.latitude, userAddress.location?.longitude].join(
                    ','
                )}&city=${userAddress.region}`,
            });
            res.end();
            return;
        }
    }

    // console.log('after getting serverSide customer', Date.now() - start);

    // const filters = await doc('settings', 'filters');

    // console.log('after getting filters', Date.now() - start);

    // const banners = await doc('banners', 'home');

    // console.log('after getting banners', Date.now() - start);

    // const recommended = await collection('restaurants', {
    //     queries: [
    //         {
    //             attribute: 'sections.isSponsored',
    //             operator: '==',
    //             value: true,
    //         },
    //         {
    //             attribute: 'status.isActive',
    //             operator: '==',
    //             value: true,
    //         },
    //     ],
    // });

    // console.log('after getting restaurants', Date.now() - start);

    const successObject = {
        data: null,
        errors: null,
        version: +new Date(),
    };

    return {
        props: {
            url: context.req.url,
            routerQuery: query || {},
            authenticated: data.authenticated,
            uid: data.uid,
            initialReduxState: {
                // settingsFilters: {
                //     ...successObject,
                //     data: filters,
                //     entity: SETTINGS_GET.entity,
                //     status: filters ? StateStatus.LOADED : StateStatus.NOT_INITIALIZED,
                // },
                // banners: {
                //     ...successObject,
                //     data: JSON.parse(JSON.stringify(banners)),
                //     entity: BANNERS_GET.entity,
                //     status: banners ? StateStatus.LOADED : StateStatus.NOT_INITIALIZED,
                // },
                currentUser: {
                    ...successObject,
                    data: JSON.parse(JSON.stringify(data.customer)),
                    entity: CURRENT_USER_GET.entity,
                    status: data.customer ? StateStatus.LOADED : StateStatus.NOT_INITIALIZED,
                },
                // recommendedRestaurants: {
                //     ...successObject.data,
                //     data: JSON.parse(JSON.stringify(recommended)),
                //     entity: RECOMMENDED_RESTAURANTS_GET.entity,
                //     status: recommended ? StateStatus.LOADED : StateStatus.NOT_INITIALIZED,
                // },
                // favoriteRestaurants: {
                //     ...successObject.data,
                //     data: JSON.parse(JSON.stringify(favorites)),
                //     entity: FAVORITE_RESTAURANTS_GET.entity,
                //     status: favorites ? StateStatus.LOADED : StateStatus.NOT_INITIALIZED,
                // },
            },
            regions: (regions || [])
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .filter(r => r.active)
                .map(r => ({
                    title: r.title,
                    id: r.id,
                    active: r.active,
                    bounds: r.bounds,
                    location: r.location,
                })),
        },
    };
}


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  